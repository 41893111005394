import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { ArrowHeadRightIcon } from "../../Constants";

export const PCNANavbar = (props) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (<>
    <div className={props.eventData && props.showBanner && (props.eventData?.bannerImage || props.eventData?.mobileBannerImage) ? "w-100" : "d-none"}>
      <div className="featured-image-container">
        <img
          alt={props.eventData ? props.eventData.bannerName : ""}
          className="featured-image d-none d-lg-block"
          src={props.eventData?.bannerImage}
        />
        <img
          alt={props.eventData ? props.eventData.bannerName : ""}
          className="featured-image d-lg-none"
          src={props.eventData?.mobileBannerImage ? props.eventData?.mobileBannerImage : props.eventData?.bannerImage}
        />
        <div className={props.eventData?.hideOverlay ? "" : "overlay"}></div>

        <div className="featured-image-details">
          <div className="container">
            <div className={props.eventData?.bannerName ? "fs-xxxl" : "d-none"}><i className={"lg theme s2"}>
              {!props.eventData?.hideBannerChevron ? (
                <ArrowHeadRightIcon />
              ) : (<></>)}
            </i>{props.eventData?.bannerName}</div>
            <div className="fs-xxl"><i className={"lg theme s2"}></i>{props.eventData?.bannerSubtitle}</div>
          </div>
        </div>
      </div>
      {/* <img className="w-100 d-none d-md-block" src={props.eventData?.bannerImage} alt={props.eventData ? props.eventData.name : ""} />
    <img className="mobile-banner w-100 d-md-none" src={props.eventData?.mobileBannerImage ? props.eventData?.mobileBannerImage : props.eventData?.bannerImage} alt={props.eventData ? props.eventData.name : ""} /> */}
    </div>
{!props.hideNav && 
    <div className="reg-container grid-item-full">



      <div className={props.eventData?.allowFAQ && props.eventData?.allowCovidBanner ? "covid-banner" : "d-none"} >Stay Healthy. Your safety is our top priority.<br className="d-md-none" /> <a href={"/" + props.eventData?.publicUrl + "/faq"}>Read our COVID-19 safety policy</a>.</div>
      <div id="subnav" className={props.eventData?.allowTerms || props.eventData?.allowFAQ || props.eventData?.eventGalleryImages?.length > 0 ? "nav-container  mb-4 mb-md-5" : "d-none"}>
        <nav className="navbar navbar-expand navbar-toggleable-sm navbar-dark">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse`}>
            <ul className="navbar-nav flex-grow-1 justify-content-center">
              {props.eventData?.allowPublic &&
                <li className="nav-item">
                  <Link className="nav-link" to={"/" + props.eventData?.publicUrl}><strong>EVENT</strong></Link>
                </li>
              }
              {props.eventData?.allowReg &&
                <li className="nav-item">
                  <Link className="nav-link" to={"/register/" + props.eventData?.publicUrl}><strong>REGISTER</strong></Link>
                </li>
              }
              <li className={props.eventData?.allowFAQ ? "nav-item" : "d-none"}>
                <Link className="nav-link" to={"/" + props.eventData?.publicUrl + "/faq"}><strong>FAQ</strong></Link>
              </li>
              <li className={props.eventData?.allowTerms ? "nav-item" : "d-none"}>
                <Link className="nav-link" to={"/" + props.eventData?.publicUrl + "/terms"}><strong>TERMS & CONDITIONS</strong></Link>
              </li>
              <li className={props.eventData?.allowGallery && props.eventData?.eventGalleryImages?.length > 0 ? "nav-item" : "d-none"}>
                <Link className="nav-link" to={"/" + props.eventData?.publicUrl + "/gallery"}><strong>GALLERY</strong></Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className={props.eventData?.allowTerms || props.eventData?.allowFAQ ? "d-none" : "mb-4 mb-md-5"}></div>

    </div>
}
  </>
  );
}